import { useContext, useEffect } from 'react'
import Router from 'next/router'

import { AppContext, SessionContext } from 'contexts'


export default function IndexPage() {
  let { LoadingWrapper } = useContext(AppContext)
  let { user } = useContext(SessionContext)
  useEffect(() => {
    if(Router.asPath.split('?')[0] == '/') {
      if(user?.home)
        Router.replace(`/media/${user.home}`)
    } else {
      // in prod we always redirect to index.html on 404s, so we use this hack
      // to force next's router to re-render once the app is loaded
      Router.replace(Router.asPath)
    }
  }, [user?.home])
  return <LoadingWrapper>Almost done</LoadingWrapper>
}
